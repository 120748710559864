import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import TermineMargin from "../components/termine-margin"
import WirUeberUnsContentHeader from "../components/wirueberuns/wirueberuns_contentHeader"
import { graphql } from "gatsby"


export const query = graphql`
  {
    allSanitySiteSettings {
      nodes {
        motto
      }
    }
    allSanityFoerderverein {
        nodes {
          spendenkonto {
            iban
            bic
          }        
          ansprechpartner {
                  _key
                  funktion
                  person {
                    firstname
                    lastname
                    phone
                  }
                }
      }
    }    
  }
`

const foerderverein = ({ data }) => (
  <Layout>
    <SEO title="Der Förderverein"/>

    <section id="content_2_schule">
      <WirUeberUnsContentHeader headline="DER FÖRDERVEREIN" motto={data.allSanitySiteSettings.nodes[0].motto}/>

      <article id="con_2_rechts">

        <h3 className="small_sub"><span style={{ color: "#007c34" }}>DER F&Ouml;RDERVEREIN</span></h3>
        <h6 className="small_sub_sub">&bdquo;PRO SCHULE&rdquo; - Verein zur F&ouml;rderung der Grundschule
          L&ouml;hne-Bahnhof
          e.V.</h6>

        <p className="text">
          <span style={{ fontWeight: "bold" }}>hat es sich zur Aufgabe gemacht, die Schule bei der Erziehung und Bildung der Sch&uuml;lerinnen und Sch&uuml;ler ideell und materiell zu unterst&uuml;tzen.</span>
          <br/><br/>
          &bdquo;Pro Schule&rdquo; k&uuml;mmert sich im Wesentlichen um<br/><br/>

          <span style={{ color: "#007C34" }}>&#9679;</span> Beschaffung von Ausstattung und Materialien, die die Ziele
          der Schule unterst&uuml;tzen und erg&auml;nzen.<br/><br/>
          <span style={{ color: "#007C34" }}>&#9679;</span> Durchf&uuml;hrung von Veranstaltungen zur besonderen
          F&ouml;rderung
          von
          Sch&uuml;lerinnen und Sch&uuml;lern, zur Information der Eltern und Unterst&uuml;tzung der
          Schulziele.<br/><br/>
          <span style={{ color: "#007C34" }}>&#9679;</span> Unterst&uuml;tzung sozial benachteiligter Kinder zur
          Teilnahme an
          Gemeinschaftsveranstaltungen.<br/> <br/>
          <h4 className="xsmall_sub">Ansprechpartner sind:</h4>

          {data.allSanityFoerderverein.nodes[0].ansprechpartner.map((item) =>
            <p className="text"><span
              style={{ fontWeight: "bold" }}>{item.person.firstname} {item.person.lastname} ({item.funktion})</span><br/>
              <span style={{ fontWeight: "bold" }}>Telefon:</span> {item.person.phone}
              <br/><br/>
            </p>
          )}

          <h4 className="xsmall_sub">Spendenkonto:</h4>

          <strong>IBAN:</strong> {data.allSanityFoerderverein.nodes[0].spendenkonto.iban}<br/>
          <strong>BIC:</strong> {data.allSanityFoerderverein.nodes[0].spendenkonto.bic}<br/>

        </p>
      </article>
      <TermineMargin/>
    </section>

  </Layout>
)

export default foerderverein
